import { types } from "constants/Notification";

const initialState = {
  notification: {
    list: {
      loading: false,
      error: "",
      data: [],
      page: 1,
      totalPage: null,
      totalData: 0,
    },
    onRead: {
      loading: false,
      error: "",
    },
  },
};

const notification = (state = initialState.notification, action) => {
  switch (action.type) {
    case types.GET_NOTIFICATION_LOADING:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };
    case types.GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          data: action.data,
          totalPage: parseInt(action.totalPage),
          totalData: parseInt(action.totalData),
          page: action.page || state?.list?.page,
        },
      };
    case types.GET_NOTIFICATION_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: action.message,
        },
      };

    case types.READ_NOTIFICATION_LOADING:
      return {
        ...state,
        onRead: {
          ...state.onRead,
          loading: true,
        },
      };
    case types.READ_NOTIFICATION_SUCCESS:
      return {
        ...state,
        onRead: {
          ...state.onRead,
          loading: false,
        },
      };
    case types.READ_NOTIFICATION_FAILED:
      return {
        ...state,
        onRead: {
          ...state.onRead,
          loading: false,
          error: action.message,
        },
      };

    default:
      return state;
  }
};

export default notification;
