import { CARD_LIMIT } from "constants/Data";

export const URL_TOPUP = {
  list: `/topup-requests?_limit=${CARD_LIMIT}%(params)s`,
  update: `/topup-requests/%(id)s/%(type)s`,
  refresh: `/topup-requests/%(id)s/refresh-status`
}

export const types = {
  GET_TOPUP_LOADING: 'GET_TOPUP_LOADING',
  GET_TOPUP_SUCCESS: 'GET_TOPUP_SUCCESS',
  GET_TOPUP_FAILED: 'GET_TOPUP_FAILED',
  STATUS_TOPUP_LOADING: 'STATUS_TOPUP_LOADING',
  STATUS_TOPUP_SUCCESS: 'STATUS_TOPUP_SUCCESS',
  STATUS_TOPUP_FAILED: 'STATUS_TOPUP_FAILED',
  REFRESH_TOPUP_LOADING: 'REFRESH_TOPUP_LOADING',
  REFRESH_TOPUP_SUCCESS: 'REFRESH_TOPUP_SUCCESS',
  REFRESH_TOPUP_FAILED: 'REFRESH_TOPUP_FAILED',
}