import { CARD_LIMIT, GAME_LIMIT } from './Data';

export const URL_GET_MERCHANTS = `/merchants?_limit=${CARD_LIMIT}%(params)s`;
export const URL_GET_DETAIL_MERCHANT = '/merchants/%(id)s';
export const URL_GET_PRODUCT_MERCHANT = `/tb-products?_limit=${CARD_LIMIT}%(params)s`;
export const URL_GET_LIVE_LEADERBOARD = `/gamified-games/%(id)s/leaderboards?_limit=${GAME_LIMIT}%(params)s`;
export const URL_PIN_GET_PRODUCT_MERCHANT = `/tb-products?_limit=20&is_pinned=true`;
export const URL_GET_DETAIL_PRODUCT_MERCHANT = `/tb-products/%(id)s`;
export const URL_GET_TRANSACTION_MERCHANT = `/tb-transactions?_limit=${CARD_LIMIT}%(params)s`;
export const URL_GET_DETAIL_TRANSACTION_MERCHANT = `/tb-transactions/%(id)s`;
export const URL_GET_MUTATION_MERCHANT = `/reports/mutations/merchants?_limit=${CARD_LIMIT}%(params)s`;
export const URL_GET_DETAIL_MUTATION_MERCHANT = `/reports/mutations/merchants/%(id)s/details?_limit=${CARD_LIMIT}%(params)s`;
export const URL_PICKUP_REQUEST = 'tb-expedition/shipping/pickup';
export const URL_UPDATE_AWB_RESI =
   'tb-expedition/shipping/%(id)s/manual/update-awb';
export const URL_REPICKUP_REQUEST = 'tb-expedition/shipping/repickup';
export const URL_PIN_TB_PRODUCT = '/tb-products';
export const URL_PIN_SORTING = URL_PIN_TB_PRODUCT + '/pinned-sorting';
export const URL_DOWNLOAD_REPORT_MERCHANTS = `/merchants/reports?_limit=${
   CARD_LIMIT + 20
}%(params)s`;
export const URL_DOWNLOAD_REPORT_PRODUCT_MERCHANT = `/tb-products/reports?_limit=${
   CARD_LIMIT + 20
}%(params)s`;
export const URL_DOWNLOAD_REPORT_TRANSACTION_MERCHANT = `/tb-transactions/reports?_limit=${
   CARD_LIMIT + 20
}%(params)s`;
export const URL_DOWNLOAD_REPORT_MUTATION_MERCHANTS = `/reports/mutations/merchants/reports?_limit=${
   CARD_LIMIT + 20
}%(params)s`;
export const URL_DOWNLOAD_REPORT_MUTATION_MERCHANT = `/reports/mutations/merchants/%(id)s/details/reports?_limit=${
   CARD_LIMIT + 20
}%(params)s`;
export const URL_DOWNLOAD_REPORT_REDUCE_WALLET_MERCHANT = `/reduce-wallets/reports?_limit=${
   CARD_LIMIT + 20
}%(params)s`;
export const URL_GET_LIST_REDUCE_WALLET_MERCHANT = `/reduce-wallets?_limit=${CARD_LIMIT}%(params)s`;
export const URL_REDUCE_WALLET_MERCHANT = `/reduce-wallets`;
export const URL_UPDATE_WALLET_MERCHANT = `/reduce-wallets/%(id)s`;
export const URL_GET_LOGS_MERCHANT = 'log/activities%(params)s';
export const URL_GET_STORE_MERCHANT = 'tb-scraping/shopee/merchant%(params)s';
export const URL_SCRAPE_MERCHANT_PRODUCT =
   'tb-scraping/shopee/scrape%(params)s';
export const URL_GET_PRODUCT_SCRAPE_MERCHANT = `tb-scraping/shopee/merchant/%(id)s/products?_limit=${CARD_LIMIT}%(params)s`;
export const URL_CONFIRM_PRODUCT_MERCHANT =
   'tb-scraping/shopee/merchant/%(id)s/confirm?%(params)s';
export const URL_CHECK_JOBS = `tb-scraping/shopee/status`;

export const types = {
   GET_MERCHANTS_LOADING: 'GET_MERCHANTS_LOADING',
   GET_MERCHANTS_SUCCESS: 'GET_MERCHANTS_SUCCESS',
   GET_MERCHANTS_FAILED: 'GET_MERCHANTS_FAILED',

   GET_DETAIL_MERCHANT_LOADING: 'GET_DETAIL_MERCHANT_LOADING',
   GET_DETAIL_MERCHANT_SUCCESS: 'GET_DETAIL_MERCHANT_SUCCESS',
   GET_DETAIL_MERCHANT_FAILED: 'GET_DETAIL_MERCHANT_FAILED',

   UPDATE_STATUS_LOADING: 'UPDATE_STATUS_LOADING',
   UPDATE_STATUS_SUCCESS: 'UPDATE_STATUS_SUCCESS',
   UPDATE_STATUS_FAILED: 'UPDATE_STATUS_FAILED',

   GET_PRODUCT_MERCHANT_LOADING: 'GET_PRODUCT_MERCHANT_LOADING',
   GET_PRODUCT_MERCHANT_SUCCESS: 'GET_PRODUCT_MERCHANT_SUCCESS',
   GET_PRODUCT_MERCHANT_FAILED: 'GET_PRODUCT_MERCHANT_FAILED',

   GET_UNPIN_PRODUCT_MERCHANT_LOADING: 'GET_UNPIN_PRODUCT_MERCHANT_LOADING',
   GET_UNPIN_PRODUCT_MERCHANT_SUCCESS: 'GET_UNPIN_PRODUCT_MERCHANT_SUCCESS',
   GET_UNPIN_PRODUCT_MERCHANT_FAILED: 'GET_UNPIN_PRODUCT_MERCHANT_FAILED',

   GET_PIN_PRODUCT_MERCHANT_LOADING: 'GET_PIN_PRODUCT_MERCHANT_LOADING',
   GET_PIN_PRODUCT_MERCHANT_SUCCESS: 'GET_PIN_PRODUCT_MERCHANT_SUCCESS',
   GET_PIN_PRODUCT_MERCHANT_FAILED: 'GET_PIN_PRODUCT_MERCHANT_FAILED',

   GET_DETAIL_PRODUCT_MERCHANT_LOADING: 'GET_DETAIL_PRODUCT_MERCHANT_LOADING',
   GET_DETAIL_PRODUCT_MERCHANT_SUCCESS: 'GET_DETAIL_PRODUCT_MERCHANT_SUCCESS',
   GET_DETAIL_PRODUCT_MERCHANT_FAILED: 'GET_DETAIL_PRODUCT_MERCHANT_FAILED',

   UPDATE_PRODUCT_LOADING: 'UPDATE_PRODUCT_LOADING',
   UPDATE_PRODUCT_SUCCESS: 'UPDATE_PRODUCT_SUCCESS',
   UPDATE_PRODUCT_FAILED: 'UPDATE_PRODUCT_FAILED',

   GET_TRANSACTION_MERCHANT_LOADING: 'GET_TRANSACTION_MERCHANT_LOADING',
   GET_TRANSACTION_MERCHANT_SUCCESS: 'GET_TRANSACTION_MERCHANT_SUCCESS',
   GET_TRANSACTION_MERCHANT_FAILED: 'GET_TRANSACTION_MERCHANT_FAILED',

   GET_DETAIL_TRANSACTION_MERCHANT_LOADING:
      'GET_DETAIL_TRANSACTION_MERCHANT_LOADING',
   GET_DETAIL_TRANSACTION_MERCHANT_SUCCESS:
      'GET_DETAIL_TRANSACTION_MERCHANT_SUCCESS',
   GET_DETAIL_TRANSACTION_MERCHANT_FAILED:
      'GET_DETAIL_TRANSACTION_MERCHANT_FAILED',

   GET_TRACKING_TRANSACTION_MERCHANT_LOADING:
      'GET_TRACKING_TRANSACTION_MERCHANT_LOADING',
   GET_TRACKING_TRANSACTION_MERCHANT_SUCCESS:
      'GET_TRACKING_TRANSACTION_MERCHANT_SUCCESS',
   GET_TRACKING_TRANSACTION_MERCHANT_FAILED:
      'GET_TRACKING_TRANSACTION_MERCHANT_FAILED',

   UPDATE_TRANSACTION_LOADING: 'UPDATE_TRANSACTION_LOADING',
   UPDATE_TRANSACTION_SUCCESS: 'UPDATE_TRANSACTION_SUCCESS',
   UPDATE_TRANSACTION_FAILED: 'UPDATE_TRANSACTION_FAILED',

   GET_LOG_MERCHANT_LOADING: 'GET_LOG_MERCHANT_LOADING',
   GET_LOG_MERCHANT_SUCCESS: 'GET_LOG_MERCHANT_SUCCESS',
   GET_LOG_MERCHANT_FAILED: 'GET_LOG_MERCHANT_FAILED',

   GET_MUTATION_MERCHANT_LOADING: 'GET_MUTATION_MERCHANT_LOADING',
   GET_MUTATION_MERCHANT_SUCCESS: 'GET_MUTATION_MERCHANT_SUCCESS',
   GET_MUTATION_MERCHANT_FAILED: 'GET_MUTATION_MERCHANT_FAILED',

   GET_DETAIL_MUTATION_MERCHANT_LOADING: 'GET_DETAIL_MUTATION_MERCHANT_LOADING',
   GET_DETAIL_MUTATION_MERCHANT_SUCCESS: 'GET_DETAIL_MUTATION_MERCHANT_SUCCESS',
   GET_DETAIL_MUTATION_MERCHANT_FAILED: 'GET_DETAIL_MUTATION_MERCHANT_FAILED',

   DOWNLOAD_REPORT_LOADING: 'DOWNLOAD_REPORT_LOADING',
   DOWNLOAD_REPORT_SUCCESS: 'DOWNLOAD_REPORT_SUCCESS',
   DOWNLOAD_REPORT_FAILED: 'DOWNLOAD_REPORT_FAILED',

   GET_LIST_REDUCE_WALLET_MERCHANT_LOADING:
      'GET_LIST_REDUCE_WALLET_MERCHANT_LOADING',
   GET_LIST_REDUCE_WALLET_MERCHANT_SUCCESS:
      'GET_LIST_REDUCE_WALLET_MERCHANT_SUCCESS',
   GET_LIST_REDUCE_WALLET_MERCHANT_FAILED:
      'GET_LIST_REDUCE_WALLET_MERCHANT_FAILED',

   REDUCE_WALLET_MERCHANT_LOADING: 'REDUCE_WALLET_MERCHANT_LOADING',
   REDUCE_WALLET_MERCHANT_SUCCESS: 'REDUCE_WALLET_MERCHANT_SUCCESS',
   REDUCE_WALLET_MERCHANT_FAILED: 'REDUCE_WALLET_MERCHANT_FAILED',

   UPDATE_WALLET_MERCHANT_LOADING: 'UPDATE_WALLET_MERCHANT_LOADING',
   UPDATE_WALLET_MERCHANT_SUCCESS: 'UPDATE_WALLET_MERCHANT_SUCCESS',
   UPDATE_WALLET_MERCHANT_FAILED: 'UPDATE_WALLET_MERCHANT_FAILED',

   GET_STORE_MERCHANT_LOADING: 'GET_STORE_MERCHANT_LOADING',
   GET_STORE_MERCHANT_SUCCESS: 'GET_STORE_MERCHANT_SUCCESS',
   GET_STORE_MERCHANT_FAILED: 'GET_STORE_MERCHANT_FAILED',

   SCRAPE_MERCHANT_PRODUCT_LOADING: 'SCRAPE_MERCHANT_PRODUCT_LOADING',
   SCRAPE_MERCHANT_PRODUCT_SUCCESS: 'SCRAPE_MERCHANT_PRODUCT_SUCCESS',
   SCRAPE_MERCHANT_PRODUCT_FAILED: 'SCRAPE_MERCHANT_PRODUCT_FAILED',

   GET_PRODUCT_SCRAPE_MERCHANT_LOADING: 'GET_PRODUCT_SCRAPE_MERCHANT_LOADING',
   GET_PRODUCT_SCRAPE_MERCHANT_SUCCESS: 'GET_PRODUCT_SCRAPE_MERCHANT_SUCCESS',
   GET_PRODUCT_SCRAPE_MERCHANT_FAILED: 'GET_PRODUCT_SCRAPE_MERCHANT_FAILED',

   CONFIRM_PRODUCT_MERCHANT_LOADING: 'CONFIRM_PRODUCT_MERCHANT_LOADING',
   CONFIRM_PRODUCT_MERCHANT_SUCCESS: 'CONFIRM_PRODUCT_MERCHANT_SUCCESS',
   CONFIRM_PRODUCT_MERCHANT_FAILED: 'CONFIRM_PRODUCT_MERCHANT_FAILED',

   GET_LIVE_LEADERBOARD_LOADING: 'GET_LIVE_LEADERBOARD_LOADING',
   GET_LIVE_LEADERBOARD_SUCCESS: 'GET_LIVE_LEADERBOARD_SUCCESS',
   GET_LIVE_LEADERBOARD_FAILED: 'GET_LIVE_LEADERBOARD_FAILED',
   GET_LIVE_LEADERBOARD_RESET: 'GET_LIVE_LEADERBOARD_RESET',
};
