import { CARD_LIMIT } from "./Data";

export const URL_CREATE_ROOM = "/gamified-rooms";
export const URL_GET_LIST_ROOM = `/gamified-rooms?_limit=${CARD_LIMIT}%(params)s`;
export const URL_GET_DETAIL_ROOM = `/gamified-rooms/%(id)s`;
export const URL_CANCEL_ROOM = `/gamified-rooms/%(id)s/cancel`;

export const type = {
  CREATE_ROOM_LOADING: "CREATE_ROOM_LOADING",
  CREATE_ROOM_SUCCESS: "CREATE_ROOM_SUCCESS",
  CREATE_ROOM_FAILED: "CREATE_ROOM_FAILED",

  GET_LIST_ROOM_LOADING: "GET_LIST_ROOM_LOADING",
  GET_LIST_ROOM_SUCCESS: "GET_LIST_ROOM_SUCCESS",
  GET_LIST_ROOM_FAILED: "GET_LIST_ROOM_FAILED",

  GET_DETAIL_ROOM_LOADING: "GET_DETAIL_ROOM_LOADING",
  GET_DETAIL_ROOM_SUCCESS: "GET_DETAIL_ROOM_SUCCESS",
  GET_DETAIL_ROOM_FAILED: "GET_DETAIL_ROOM_FAILED",

  CANCEL_ROOM_LOADING: "CANCEL_ROOM_LOADING",
  CANCEL_ROOM_SUCCESS: "CANCEL_ROOM_SUCCESS",
  CANCEL_ROOM_FAILED: "CANCEL_ROOM_FAILED",
};

export const statusMapRoom = {
  WAITING_PLAYER: "Menunggu Pemain",
  CANCELED: "Dibatalkan",
  FINISHED: "Selesai",
  WAITING_PAYMENT: "Menunggu Pembayaran",
  PLAYING: "Bermain",
};
