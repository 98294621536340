const { types } = require('constants/Merchant');

const initialState = {
   merchant: {
      listMerchant: {
         list: {
            loading: false,
            error: '',
            data: [],
            page: 1,
            totalPage: null,
            totalData: 0,
         },
         detail: {
            loading: false,
            data: {},
            error: '',
         },
         onUpdate: {
            loading: false,
            error: '',
         },
      },
      product: {
         list: {
            loading: false,
            error: '',
            data: [],
            page: 1,
            totalPage: null,
            totalData: 0,
         },
         detail: {
            loading: false,
            data: {},
            error: '',
         },
         onUpdate: {
            loading: false,
            error: '',
         },
      },
      unpinedProduct: {
         list: {
            loading: false,
            error: '',
            data: [],
            page: 1,
            totalData: 0,
            isLoadMore: false,
            isEnd: false,
            search: '',
         },
      },
      pinProduct: {
         list: {
            loading: false,
            error: '',
            data: [],
         },
      },
      transaction: {
         list: {
            loading: false,
            error: '',
            data: [],
            page: 1,
            totalPage: null,
            totalData: 0,
         },
         detail: {
            loading: false,
            data: {},
            error: '',
         },
         tracking: {
            loading: false,
            data: {},
            error: '',
         },
         onUpdate: {
            loading: false,
            error: '',
         },
      },
      mutation: {
         list: {
            loading: false,
            error: '',
            data: [],
            page: 1,
            totalPage: null,
            totalData: 0,
         },
         detail: {
            loading: false,
            error: '',
            data: [],
            page: 1,
            totalPage: null,
            totalData: 0,
         },
      },
      reduceWallet: {
         list: {
            loading: false,
            error: '',
            data: [],
            page: 1,
            totalPage: null,
            totalData: 0,
            totalPermintaan: 0,
         },
         onReduce: {
            loading: false,
            error: '',
         },
         onUpdate: {
            loading: false,
            error: '',
         },
      },
      onReport: {
         loading: false,
         error: '',
      },
      logs: {
         loading: false,
         error: '',
         data: [],
         page: 1,
         totalPage: null,
         totalData: 0,
      },
      storeMerchant: {
         info: {
            loading: false,
            data: {},
            error: '',
         },
         onScrape: {
            loading: false,
            error: '',
         },
         listProduct: {
            loading: false,
            error: '',
            data: [],
            page: 1,
            totalPage: null,
            totalData: 0,
         },
         onConfirm: {
            loading: false,
            error: '',
         },
      },
   },
};

const merchant = (state = initialState.merchant, action) => {
   switch (action.type) {
      case types.GET_MERCHANTS_LOADING:
         return {
            ...state,
            listMerchant: {
               ...state.listMerchant,
               list: {
                  ...state.list,
                  loading: true,
               },
            },
         };
      case types.GET_MERCHANTS_SUCCESS:
         return {
            ...state,
            listMerchant: {
               ...state.listMerchant,
               list: {
                  ...state.list,
                  loading: false,
                  data: action.data,
                  totalPage: parseInt(action.totalPage),
                  totalData: parseInt(action.totalData),
                  page: action.page || state.list.page,
               },
            },
         };
      case types.GET_MERCHANTS_FAILED:
         return {
            ...state,
            listMerchant: {
               ...state.listMerchant,
               list: {
                  ...state.list,
                  loading: false,
                  error: action.message,
               },
            },
         };
      case types.GET_DETAIL_MERCHANT_LOADING:
         return {
            ...state,
            listMerchant: {
               ...state.listMerchant,
               detail: {
                  ...state.detail,
                  loading: true,
               },
            },
         };
      case types.GET_DETAIL_MERCHANT_SUCCESS:
         return {
            ...state,
            listMerchant: {
               ...state.listMerchant,
               detail: {
                  ...state.detail,
                  loading: false,
                  data: action.data,
               },
            },
         };
      case types.GET_DETAIL_MERCHANT_FAILED:
         return {
            ...state,
            listMerchant: {
               ...state.listMerchant,
               detail: {
                  ...state.detail,
                  loading: false,
                  error: action.message,
               },
            },
         };
      case types.UPDATE_STATUS_LOADING:
         return {
            ...state,
            listMerchant: {
               ...state.listMerchant,
               onUpdate: {
                  ...state.onUpdate,
                  loading: true,
               },
            },
         };
      case types.UPDATE_STATUS_SUCCESS:
         return {
            ...state,
            listMerchant: {
               ...state.listMerchant,
               onUpdate: {
                  ...state.onUpdate,
                  loading: false,
               },
            },
         };
      case types.UPDATE_STATUS_FAILED:
         return {
            ...state,
            listMerchant: {
               ...state.listMerchant,
               onUpdate: {
                  ...state.onUpdate,
                  loading: false,
                  error: action.message,
               },
            },
         };
      case types.DOWNLOAD_REPORT_LOADING:
         return {
            ...state,
            onReport: {
               ...state.onReport,
               loading: true,
            },
         };
      case types.DOWNLOAD_REPORT_SUCCESS:
         return {
            ...state,
            onReport: {
               ...state.onReport,
               loading: false,
            },
         };
      case types.DOWNLOAD_REPORT_FAILED:
         return {
            ...state,
            onReport: {
               ...state.onReport,
               loading: false,
               error: action.message,
            },
         };
      case types.GET_LOG_MERCHANT_LOADING:
         return {
            ...state,
            logs: {
               ...state.logs,
               loading: true,
            },
         };
      case types.GET_LOG_MERCHANT_SUCCESS:
         return {
            ...state,
            logs: {
               ...state.logs,
               loading: false,
               data: action.data,
               totalPage: parseInt(action.totalPage),
               totalData: parseInt(action.totalData),
               page: action.page || state.logs.page,
            },
         };
      case types.GET_LOG_MERCHANT_FAILED:
         return {
            ...state,
            logs: {
               ...state.logs,
               loading: false,
               error: action.message,
            },
         };

      case types.GET_PRODUCT_MERCHANT_LOADING:
         return {
            ...state,
            product: {
               ...state.product,
               list: {
                  ...state.list,
                  loading: true,
               },
            },
         };
      case types.GET_PRODUCT_MERCHANT_SUCCESS:
         return {
            ...state,
            product: {
               ...state.product,
               list: {
                  ...state.list,
                  loading: false,
                  data: action.data,
                  totalPage: parseInt(action.totalPage),
                  totalData: parseInt(action.totalData),
                  page: action.page || state.list.page,
               },
            },
         };
      case types.GET_PRODUCT_MERCHANT_FAILED:
         return {
            ...state,
            product: {
               ...state.product,
               list: {
                  ...state?.list,
                  loading: false,
                  error: action.message,
               },
            },
         };
      case types.GET_UNPIN_PRODUCT_MERCHANT_LOADING:
         return {
            ...state,
            unpinedProduct: {
               ...state?.unpinedProduct,
               list: {
                  ...state?.unpinedProduct?.list,
                  loading: action?.isLoadMore ? false : true,
                  isLoadMore: action?.isLoadMore,
                  isEnd: false,
                  search: action?.search,
               },
            },
         };
      case types.GET_UNPIN_PRODUCT_MERCHANT_SUCCESS:
         return {
            ...state,
            unpinedProduct: {
               ...state.unpinedProduct,
               list: {
                  ...state?.unpinedProduct?.list,
                  loading: false,
                  data: action?.isLoadMore
                     ? [...state?.unpinedProduct?.list?.data, ...action.data]
                     : action.data,
                  totalData: parseInt(action.totalData),
                  page: action.page || state?.list?.page || 1,
                  isEnd: !action?.data?.length,
               },
            },
         };
      case types.GET_UNPIN_PRODUCT_MERCHANT_FAILED:
         return {
            ...state,
            unpinedProduct: {
               ...state.unpinedProduct,
               list: {
                  ...state?.unpinedProduct?.list,
                  loading: false,
                  error: action.message,
                  isLoadMore: false,
                  isEnd: false,
               },
            },
         };
      case types.GET_PIN_PRODUCT_MERCHANT_LOADING:
         return {
            ...state,
            pinProduct: {
               ...state.pinProduct,
               list: {
                  ...state.list,
                  loading: true,
               },
            },
         };
      case types.GET_PIN_PRODUCT_MERCHANT_SUCCESS:
         return {
            ...state,
            pinProduct: {
               ...state.pinProduct,
               list: {
                  ...state.list,
                  loading: false,
                  data: action.data,
               },
            },
         };
      case types.GET_PIN_PRODUCT_MERCHANT_FAILED:
         return {
            ...state,
            pinProduct: {
               ...state.pinProduct,
               list: {
                  ...state.list,
                  loading: false,
                  error: action.message,
               },
            },
         };

      case types.GET_DETAIL_PRODUCT_MERCHANT_LOADING:
         return {
            ...state,
            product: {
               ...state.product,
               detail: {
                  ...state.detail,
                  loading: true,
               },
            },
         };
      case types.GET_DETAIL_PRODUCT_MERCHANT_SUCCESS:
         return {
            ...state,
            product: {
               ...state.product,
               detail: {
                  ...state.detail,
                  loading: false,
                  data: action.data,
               },
            },
         };
      case types.GET_DETAIL_PRODUCT_MERCHANT_FAILED:
         return {
            ...state,
            product: {
               ...state.product,
               detail: {
                  ...state.detail,
                  loading: false,
                  error: action.message,
               },
            },
         };
      case types.UPDATE_PRODUCT_LOADING:
         return {
            ...state,
            product: {
               ...state.product,
               onUpdate: {
                  ...state.onUpdate,
                  loading: true,
               },
            },
         };
      case types.UPDATE_PRODUCT_SUCCESS:
         return {
            ...state,
            product: {
               ...state.product,
               onUpdate: {
                  ...state.onUpdate,
                  loading: false,
               },
            },
         };
      case types.UPDATE_PRODUCT_FAILED:
         return {
            ...state,
            product: {
               ...state.product,
               onUpdate: {
                  ...state.onUpdate,
                  loading: false,
                  error: action.message,
               },
            },
         };

      case types.GET_TRANSACTION_MERCHANT_LOADING:
         return {
            ...state,
            transaction: {
               ...state.transaction,
               list: {
                  ...state.list,
                  loading: true,
               },
            },
         };
      case types.GET_TRANSACTION_MERCHANT_SUCCESS:
         return {
            ...state,
            transaction: {
               ...state.transaction,
               list: {
                  ...state.list,
                  loading: false,
                  data: action.data,
                  totalPage: parseInt(action.totalPage),
                  totalData: parseInt(action.totalData),
                  page: action.page || state.list.page,
               },
            },
         };
      case types.GET_TRANSACTION_MERCHANT_FAILED:
         return {
            ...state,
            transaction: {
               ...state.transaction,
               list: {
                  ...state.list,
                  loading: false,
                  error: action.message,
               },
            },
         };
      case types.GET_DETAIL_TRANSACTION_MERCHANT_LOADING:
         return {
            ...state,
            transaction: {
               ...state.transaction,
               detail: {
                  ...state.detail,
                  loading: true,
               },
            },
         };
      case types.GET_DETAIL_TRANSACTION_MERCHANT_SUCCESS:
         return {
            ...state,
            transaction: {
               ...state.transaction,
               detail: {
                  ...state.detail,
                  loading: false,
                  data: action.data,
               },
            },
         };
      case types.GET_DETAIL_TRANSACTION_MERCHANT_FAILED:
         return {
            ...state,
            transaction: {
               ...state.transaction,
               detail: {
                  ...state.detail,
                  loading: false,
                  error: action.message,
               },
            },
         };
      case types.GET_TRACKING_TRANSACTION_MERCHANT_LOADING:
         return {
            ...state,
            transaction: {
               ...state.transaction,
               tracking: {
                  ...state.tracking,
                  loading: true,
               },
            },
         };
      case types.GET_TRACKING_TRANSACTION_MERCHANT_SUCCESS:
         return {
            ...state,
            transaction: {
               ...state.transaction,
               tracking: {
                  ...state.tracking,
                  loading: false,
                  data: action.data,
               },
            },
         };
      case types.GET_TRACKING_TRANSACTION_MERCHANT_FAILED:
         return {
            ...state,
            transaction: {
               ...state.transaction,
               tracking: {
                  ...state.tracking,
                  loading: false,
                  error: action.message,
               },
            },
         };
      case types.UPDATE_TRANSACTION_LOADING:
         return {
            ...state,
            transaction: {
               ...state.transaction,
               onUpdate: {
                  ...state.onUpdate,
                  loading: true,
               },
            },
         };
      case types.UPDATE_TRANSACTION_SUCCESS:
         return {
            ...state,
            transaction: {
               ...state.transaction,
               onUpdate: {
                  ...state.onUpdate,
                  loading: false,
               },
            },
         };
      case types.UPDATE_TRANSACTION_FAILED:
         return {
            ...state,
            transaction: {
               ...state.transaction,
               onUpdate: {
                  ...state.onUpdate,
                  loading: false,
                  error: action.message,
               },
            },
         };

      case types.GET_MUTATION_MERCHANT_LOADING:
         return {
            ...state,
            mutation: {
               ...state.mutation,
               list: {
                  ...state.list,
                  loading: true,
               },
            },
         };
      case types.GET_MUTATION_MERCHANT_SUCCESS:
         return {
            ...state,
            mutation: {
               ...state.mutation,
               list: {
                  ...state.list,
                  loading: false,
                  data: action.data,
                  totalPage: parseInt(action.totalPage),
                  totalData: parseInt(action.totalData),
                  page: action.page || state.list.page,
               },
            },
         };
      case types.GET_MUTATION_MERCHANT_FAILED:
         return {
            ...state,
            mutation: {
               ...state.mutation,
               list: {
                  ...state.list,
                  loading: false,
                  error: action.message,
               },
            },
         };
      case types.GET_DETAIL_MUTATION_MERCHANT_LOADING:
         return {
            ...state,
            mutation: {
               ...state.mutation,
               detail: {
                  ...state.detail,
                  loading: true,
               },
            },
         };
      case types.GET_DETAIL_MUTATION_MERCHANT_SUCCESS:
         return {
            ...state,
            mutation: {
               ...state.mutation,
               detail: {
                  ...state.detail,
                  loading: false,
                  data: action.data,
                  totalPage: parseInt(action.totalPage),
                  totalData: parseInt(action.totalData),
                  page: action.page || state.detail.page,
               },
            },
         };
      case types.GET_DETAIL_MUTATION_MERCHANT_FAILED:
         return {
            ...state,
            mutation: {
               ...state.mutation,
               detail: {
                  ...state.detail,
                  loading: false,
                  error: action.message,
               },
            },
         };

      case types.GET_LIST_REDUCE_WALLET_MERCHANT_LOADING:
         return {
            ...state,
            reduceWallet: {
               ...state.reduceWallet,
               list: {
                  ...state.reduceWallet.list,
                  loading: true,
               },
            },
         };
      case types.GET_LIST_REDUCE_WALLET_MERCHANT_SUCCESS:
         return {
            ...state,
            reduceWallet: {
               ...state.reduceWallet,
               list: {
                  ...state.reduceWallet.list,
                  loading: false,
                  data: action.data,
                  totalPage: parseInt(action.totalPage),
                  totalData: parseInt(action.totalData),
                  totalPermintaan:
                     action.status !== 'ACCEPT'
                        ? parseInt(action.totalData)
                        : state.reduceWallet.list.totalPermintaan,
                  page: action.page || state.list.page,
               },
            },
         };
      case types.GET_LIST_REDUCE_WALLET_MERCHANT_FAILED:
         return {
            ...state,
            reduceWallet: {
               ...state.reduceWallet,
               list: {
                  ...state.reduceWallet.list,
                  loading: false,
                  error: action.message,
               },
            },
         };
      case types.REDUCE_WALLET_MERCHANT_LOADING:
         return {
            ...state,
            reduceWallet: {
               ...state.reduceWallet,
               onReduce: {
                  ...state.reduceWallet.onReduce,
                  loading: true,
               },
            },
         };
      case types.REDUCE_WALLET_MERCHANT_SUCCESS:
         return {
            ...state,
            reduceWallet: {
               ...state.reduceWallet,
               onReduce: {
                  ...state.reduceWallet.onReduce,
                  loading: false,
               },
            },
         };
      case types.REDUCE_WALLET_MERCHANT_FAILED:
         return {
            ...state,
            reduceWallet: {
               ...state.reduceWallet,
               onReduce: {
                  ...state.reduceWallet.onReduce,
                  loading: false,
                  error: action.message,
               },
            },
         };
      case types.UPDATE_WALLET_MERCHANT_LOADING:
         return {
            ...state,
            reduceWallet: {
               ...state.reduceWallet,
               onUpdate: {
                  ...state.reduceWallet.onUpdate,
                  loading: true,
               },
            },
         };
      case types.UPDATE_WALLET_MERCHANT_SUCCESS:
         return {
            ...state,
            reduceWallet: {
               ...state.reduceWallet,
               onUpdate: {
                  ...state.reduceWallet.onUpdate,
                  loading: false,
               },
            },
         };
      case types.UPDATE_WALLET_MERCHANT_FAILED:
         return {
            ...state,
            reduceWallet: {
               ...state.reduceWallet,
               onUpdate: {
                  ...state.reduceWallet.onUpdate,
                  loading: false,
                  error: action.message,
               },
            },
         };

      case types.GET_STORE_MERCHANT_LOADING:
         return {
            ...state,
            storeMerchant: {
               ...state.storeMerchant,
               info: {
                  ...state.storeMerchant.info,
                  loading: true,
               },
            },
         };
      case types.GET_STORE_MERCHANT_SUCCESS:
         return {
            ...state,
            storeMerchant: {
               ...state.storeMerchant,
               info: {
                  ...state.storeMerchant.info,
                  loading: false,
                  data: action.data,
               },
            },
         };
      case types.GET_STORE_MERCHANT_FAILED:
         return {
            ...state,
            storeMerchant: {
               ...state.storeMerchant,
               info: {
                  ...state.storeMerchant.info,
                  loading: false,
                  error: action.message,
               },
            },
         };
      case types.SCRAPE_MERCHANT_PRODUCT_LOADING:
         return {
            ...state,
            storeMerchant: {
               ...state.storeMerchant,
               onScrape: {
                  ...state.storeMerchant.onScrape,
                  loading: true,
               },
            },
         };
      case types.SCRAPE_MERCHANT_PRODUCT_SUCCESS:
         return {
            ...state,
            storeMerchant: {
               ...state.storeMerchant,
               onScrape: {
                  ...state.storeMerchant.onScrape,
                  loading: false,
               },
            },
         };
      case types.SCRAPE_MERCHANT_PRODUCT_FAILED:
         return {
            ...state,
            storeMerchant: {
               ...state.storeMerchant,
               onScrape: {
                  ...state.storeMerchant.onScrape,
                  loading: false,
                  error: action.message,
               },
            },
         };
      case types.GET_PRODUCT_SCRAPE_MERCHANT_LOADING:
         return {
            ...state,
            storeMerchant: {
               ...state.storeMerchant,
               listProduct: {
                  ...state.storeMerchant.listProduct,
                  loading: true,
               },
            },
         };
      case types.GET_PRODUCT_SCRAPE_MERCHANT_SUCCESS:
         return {
            ...state,
            storeMerchant: {
               ...state.storeMerchant,
               listProduct: {
                  ...state.storeMerchant.listProduct,
                  loading: false,
                  data: action.data,
                  totalPage: parseInt(action.totalPage),
                  totalData: parseInt(action.totalData),
                  page: action.page || state.list.page,
               },
            },
         };
      case types.GET_PRODUCT_SCRAPE_MERCHANT_FAILED:
         return {
            ...state,
            storeMerchant: {
               ...state.storeMerchant,
               listProduct: {
                  ...state.storeMerchant.listProduct,
                  loading: false,
                  error: action.message,
               },
            },
         };
      case types.CONFIRM_PRODUCT_MERCHANT_LOADING:
         return {
            ...state,
            scrapeProduct: {
               ...state.storeMerchant,
               onConfirm: {
                  ...state.storeMerchant.onConfirm,
                  loading: true,
               },
            },
         };
      case types.CONFIRM_PRODUCT_MERCHANT_SUCCESS:
         return {
            ...state,
            scrapeProduct: {
               ...state.storeMerchant,
               onConfirm: {
                  ...state.storeMerchant.onConfirm,
                  loading: false,
               },
            },
         };
      case types.CONFIRM_PRODUCT_MERCHANT_FAILED:
         return {
            ...state,
            scrapeProduct: {
               ...state.storeMerchant,
               onConfirm: {
                  ...state.storeMerchant.onConfirm,
                  loading: false,
                  error: action.message,
               },
            },
         };
      case types.GET_LIVE_LEADERBOARD_LOADING:
         return {
            ...state,
            product: {
               ...state.product,
               list: {
                  ...state.list,
                  loading: true,
               },
            },
         };
      case types.GET_LIVE_LEADERBOARD_SUCCESS:
         return {
            ...state,
            product: {
               ...state.product,
               list: {
                  ...state.list,
                  loading: false,
                  data: action.data,
                  totalPage: parseInt(action.totalPage),
                  totalData: parseInt(action.totalData),
                  page: action.page || state.list.page,
               },
            },
         };
      case types.GET_LIVE_LEADERBOARD_FAILED:
         return {
            ...state,
            product: {
               ...state.product,
               list: {
                  ...state?.list,
                  loading: false,
                  error: action.message,
               },
            },
         };
      case types.GET_LIVE_LEADERBOARD_RESET:
         return {
            ...state,
            product: {
               ...state.product,
               list: {
                  ...state.list,
                  loading: false,
                  data: [],
                  totalPage: 0,
                  totalData: 0,
                  page: 1,
               },
            },
         };
      default:
         return state;
   }
};

export default merchant;
