import { types } from "constants/Topup";

const initialState = {
  topupRequest: {
    listTopup: {
      loading: false,
      error: "",
      data: [],
      page: 1,
      totalPage: null,
      totalData: 0,
    },
  },
};

const topupReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TOPUP_LOADING:
      return {
        ...state,
        topupRequest: {
          ...state.topupRequest,
          listTopup: {
            ...state.topupRequest.listTopup,
            loading: true,
          },
        },
      };
    case types.GET_TOPUP_SUCCESS:
      return {
        ...state,
        topupRequest: {
          ...state.topupRequest,
          listTopup: {
            ...state.topupRequest.listTopup,
            loading: false,
            data: action.data,
            totalPage: parseInt(action.totalPage),
            totalData: parseInt(action.totalData),
            page: action.page || state.listTopup.page,
          },
        },
      };
    case types.GET_TOPUP_FAILED:
      return {
        ...state,
        topupRequest: {
          ...state.topupRequest,
          listTopup: {
            ...state.topupRequest.listTopup,
            loading: false,
            error: action.message,
          },
        },
      };

    case types.REFRESH_TOPUP_SUCCESS:
      const oldData = state.topupRequest.listTopup.data;
      const newObject = action.data;

      let index = oldData.findIndex((item) => item.id === newObject.id);

      if (index !== -1) {
        oldData[index] = newObject;
      }

      return {
        ...state,
        topupRequest: {
          ...state.topupRequest,
          listTopup: {
            ...state.topupRequest.listTopup,
            loading: false,
            data: oldData,
          },
        },
      };

    default:
      return state;
  }
};

export default topupReducer;
