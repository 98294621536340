const languageData = [
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us',
  },
  {
    languageId: 'bahasa',
    locale: 'id',
    name: 'Bahasa',
    icon: 'id',
  },
];

export default languageData;
