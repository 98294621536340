import React from "react";
import Lottie from "lottie-react";
import animationData from "resources/lottie/splashscreen.json";
import Layout from "components/layout/Main";
const style = {
  height: 300,
};
const SplashScreen = (
  <Layout className="splashscreen" style={{ width: 600 }} centerMiddle>
    <Lottie
      animationData={animationData}
      autoplay={true}
      loop={true}
      style={style}
    />
  </Layout>
);

export default SplashScreen;
