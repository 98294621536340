// URL
import { CARD_LIMIT } from './Data';
export const URL_GET_PRODUCT = `/products?_limit=${CARD_LIMIT}%(params)s`;
export const URL_GET_PRODUCT_KENDALA = `/products?is_purchasable=false&is_active=true&_order=last-updated`;
export const URL_GET_CLUSTER = `/clusters?%(params)s`;
export const URL_DETAIL_CLUSTER = `/clusters/%(id)s`;
export const URL_GET_DIGIPOS = `/digipos-frontliners?%(params)s`;
export const URL_DETAIL_DIGIPOS = `/digipos-frontliners/%(id)s`;
export const URL_DIGIPOS = `/digipos-frontliners`;
export const URL_CHANGE_DEFAULT_DIGIPOS = `/digipos-frontliners/%(id)s/set-default`;
export const URL_CHANGE_ACTIVE_DIGIPOS = `/digipos-frontliners/%(id)s/set-active`;
export const URL_GET_REGENCY = `/regencies?%(params)s`;
export const URL_PRODUCT = `/products`;
export const URL_CLUSTER = `/clusters`;
export const URL_DETAIL_PRODUCT = `/products/%(id)s`;
export const URL_SET_ALL_PRICE_PRODUCT = `/products/%(id)s/set-price-regencies`;
export const URL_GET_PRODUCT_PRICE = `product-prices?_limit=${CARD_LIMIT}%(params)s`;
export const URL_DETAIL_PRICE = `product-prices/%(id)s`;
export const URL_REPORTING_PRODUCT = `/products/reports?_limit=${CARD_LIMIT}%(params)s`;
export const URL_REPORTING_PRODUCT_PRICE = `/product-prices/reports?_limit=${CARD_LIMIT}%(params)s`;
export const URL_PHYSICAL_VOUCHER = `/physical-vouchers?_limit=${CARD_LIMIT}%(params)s`;
export const URL_PHYSICAL_VOUCHER_SUMMARY = `/physical-vouchers/products?_limit=${CARD_LIMIT}%(params)s`;
export const RECALL_PHYSICAL_VOUCHER = `/physical-voucher-histories/%(id)s/recall`;
export const URL_FIND_PRODUCT = `/physical-vouchers/products?product_brand_id=%(id)s&_limit=${CARD_LIMIT}%(params)s`;
export const URL_CREATE_PHYSICAL_VOUCHER = `/physical-vouchers`;
export const URL_UPDATE_MULTIPLE_RETUR = `/physical-vouchers/update-multiple-retur`;
export const URL_PRODUCT_CLUSTER = `/products/%(id)s/product-clusters`;
export const URL_VOUCHER_HISTORY = `/physical-voucher-histories?_limit=${CARD_LIMIT}%(params)s`;
export const URL_VOUCHER_COUNT = `/physical-vouchers/counts`;
export const URL_ID_VOUCHER_FISIK = `/product-categories?code=PHYSICAL_VOUCHER`;
// Types
export const types = {
   GET_PRODUCT_LOADING: 'GET_PRODUCT_LOADING',
   GET_PRODUCT_SUCCESS: 'GET_PRODUCT_SUCCESS',
   GET_PRODUCT_FAILED: 'GET_PRODUCT_FAILED',

   GET_CLUSTER_LOADING: 'GET_CLUSTER_LOADING',
   GET_CLUSTER_SUCCESS: 'GET_CLUSTER_SUCCESS',
   GET_CLUSTER_FAILED: 'GET_CLUSTER_FAILED',

   GET_DIGIPOS_LOADING: 'GET_DIGIPOS_LOADING',
   GET_DIGIPOS_SUCCESS: 'GET_DIGIPOS_SUCCESS',
   GET_DIGIPOS_FAILED: 'GET_DIGIPOS_FAILED',

   SHOW_CLUSTER_LOADING: 'SHOW_CLUSTER_LOADING',
   SHOW_CLUSTER_SUCCESS: 'SHOW_CLUSTER_SUCCESS',
   SHOW_CLUSTER_FAILED: 'SHOW_CLUSTER_FAILED',

   SHOW_DIGIPOS_LOADING: 'SHOW_DIGIPOS_LOADING',
   SHOW_DIGIPOS_SUCCESS: 'SHOW_DIGIPOS_SUCCESS',
   SHOW_DIGIPOS_FAILED: 'SHOW_DIGIPOS_FAILED',

   CREATE_DIGIPOS_LOADING: 'CREATE_DIGIPOS_LOADING',
   CREATE_DIGIPOS_SUCCESS: 'CREATE_DIGIPOS_SUCCESS',
   CREATE_DIGIPOS_FAILED: 'CREATE_DIGIPOS_FAILED',

   GET_REGENCY_LOADING: 'GET_REGENCY_LOADING',
   GET_REGENCY_SUCCESS: 'GET_REGENCY_SUCCESS',
   GET_REGENCY_FAILED: 'GET_REGENCY_FAILED',

   GET_RECALL_VOUCHER_FISIK_LOADING: 'GET_RECALL_VOUCHER_FISIK_LOADING',
   GET_RECALL_VOUCHER_FISIK_SUCCESS: 'GET_RECALL_VOUCHER_FISIK_SUCCESS',
   GET_RECALL_VOUCHER_FISIK_FAILED: 'GET_RECALL_VOUCHER_FISIK_FAILED',

   GET_COUNT_VOUCHER_LOADING: 'GET_COUNT_VOUCHER_LOADING',
   GET_COUNT_VOUCHER_SUCCESS: 'GET_COUNT_VOUCHER_SUCCESS',
   GET_COUNT_VOUCHER_FAILED: 'GET_COUNT_VOUCHER_FAILED',

   GET_VOUCHER_HISTORY_LOADING: 'GET_VOUCHER_HISTORY_LOADING',
   GET_VOUCHER_HISTORY_SUCCESS: 'GET_VOUCHER_HISTORY_SUCCESS',
   GET_VOUCHER_HISTORY_FAILED: 'GET_VOUCHER_HISTORY_FAILED',

   UPDATE_MULTIPLE_RETUR_LOADING: 'UPDATE_MULTIPLE_RETUR_LOADING',
   UPDATE_MULTIPLE_RETUR_SUCCESS: 'UPDATE_MULTIPLE_RETUR_SUCCESS',
   UPDATE_MULTIPLE_RETUR_FAILED: 'UPDATE_MULTIPLE_RETUR_FAILED',

   GET_PHYSICAL_VOUCHER_LOADING: 'GET_PHYSICAL_VOUCHER_LOADING',
   GET_PHYSICAL_VOUCHER_SUCCESS: 'GET_PHYSICAL_VOUCHER_SUCCESS',
   GET_PHYSICAL_VOUCHER_FAILED: 'GET_PHYSICAL_VOUCHER_FAILED',

   GET_FIND_PRODUCT_VOUCHER_LOADING: 'GET_FIND_PRODUCT_VOUCHER_LOADING',
   GET_FIND_PRODUCT_VOUCHER_SUCCESS: 'GET_FIND_PRODUCT_VOUCHER_SUCCESS',
   GET_FIND_PRODUCT_VOUCHER_FAILED: 'GET_FIND_PRODUCT_VOUCHER_FAILED',

   CREATE_PHYSICAL_VOUCHER_LOADING: 'CREATE_PHYSICAL_VOUCHER_LOADING',
   CREATE_PHYSICAL_VOUCHER_SUCCESS: 'CREATE_PHYSICAL_VOUCHER_SUCCESS',
   CREATE_PHYSICAL_VOUCHER_FAILED: 'CREATE_PHYSICAL_VOUCHER_FAILED',

   GET_PHYSICAL_VOUCHER_SUMMARY_LOADING: 'GET_PHYSICAL_VOUCHER_SUMMARY_LOADING',
   GET_PHYSICAL_VOUCHER_SUMMARY_SUCCESS: 'GET_PHYSICAL_VOUCHER_SUMMARY_SUCCESS',
   GET_PHYSICAL_VOUCHER_SUMMARY_FAILED: 'GET_PHYSICAL_VOUCHER_SUMMARY_FAILED',

   CREATE_PRODUCT_LOADING: 'CREATE_PRODUCT_LOADING',
   CREATE_PRODUCT_SUCCESS: 'CREATE_PRODUCT_SUCCESS',
   CREATE_PRODUCT_FAILED: 'CREATE_PRODUCT_FAILED',

   CREATE_CLUSTER_LOADING: 'CREATE_CLUSTER_LOADING',
   CREATE_CLUSTER_SUCCESS: 'CREATE_CLUSTER_SUCCESS',
   CREATE_CLUSTER_FAILED: 'CREATE_CLUSTER_FAILED',

   DELETE_CLUSTER_LOADING: 'DELETE_CLUSTER_LOADING',
   DELETE_CLUSTER_SUCCESS: 'DELETE_CLUSTER_SUCCESS',
   DELETE_CLUSTER_FAILED: 'DELETE_CLUSTER_FAILED',

   DELETE_DIGIPOS_LOADING: 'DELETE_DIGIPOS_LOADING',
   DELETE_DIGIPOS_SUCCESS: 'DELETE_DIGIPOS_SUCCESS',
   DELETE_DIGIPOS_FAILED: 'DELETE_DIGIPOS_FAILED',

   CHANGE_DEFAULT_DIGIPOS_LOADING: 'CHANGE_DEFAULT_DIGIPOS_LOADING',
   CHANGE_DEFAULT_DIGIPOS_SUCCESS: 'CHANGE_DEFAULT_DIGIPOS_SUCCESS',
   CHANGE_DEFAULT_DIGIPOS_FAILED: 'CHANGE_DEFAULT_DIGIPOS_FAILED',

   CHANGE_ACTIVE_DIGIPOS_LOADING: 'CHANGE_ACTIVE_DIGIPOS_LOADING',
   CHANGE_ACTIVE_DIGIPOS_SUCCESS: 'CHANGE_ACTIVE_DIGIPOS_SUCCESS',
   CHANGE_ACTIVE_DIGIPOS_FAILED: 'CHANGE_ACTIVE_DIGIPOS_FAILED',

   DELETE_PRODUCT_LOADING: 'DELETE_PRODUCT_LOADING',
   DELETE_PRODUCT_SUCCESS: 'DELETE_PRODUCT_SUCCESS',
   DELETE_PRODUCT_FAILED: 'DELETE_PRODUCT_FAILED',

   UPDATE_PRODUCT_LOADING: 'UPDATE_PRODUCT_LOADING',
   UPDATE_PRODUCT_SUCCESS: 'UPDATE_PRODUCT_SUCCESS',
   UPDATE_PRODUCT_FAILED: 'UPDATE_PRODUCT_FAILED',

   UPDATE_CLUSTER_LOADING: 'UPDATE_CLUSTER_LOADING',
   UPDATE_CLUSTER_SUCCESS: 'UPDATE_CLUSTER_SUCCESS',
   UPDATE_CLUSTER_FAILED: 'UPDATE_CLUSTER_FAILED',

   UPDATE_DIGIPOS_LOADING: 'UPDATE_DIGIPOS_LOADING',
   UPDATE_DIGIPOS_SUCCESS: 'UPDATE_DIGIPOS_SUCCESS',
   UPDATE_DIGIPOS_FAILED: 'UPDATE_DIGIPOS_FAILED',

   SHOW_PRODUCT_LOADING: 'SHOW_PRODUCT_LOADING',
   SHOW_PRODUCT_SUCCESS: 'SHOW_PRODUCT_SUCCESS',
   SHOW_PRODUCT_FAILED: 'SHOW_PRODUCT_FAILED',

   GET_PRODUCT_PRICE_LOADING: 'GET_PRODUCT_PRICE_LOADING',
   GET_PRODUCT_PRICE_SUCCESS: 'GET_PRODUCT_PRICE_SUCCESS',
   GET_PRODUCT_PRICE_FAILED: 'GET_PRODUCT_PRICE_FAILED',

   UPDATE_PRODUCT_PRICE_LOADING: 'UPDATE_PRODUCT_PRICE_LOADING',
   UPDATE_PRODUCT_PRICE_SUCCESS: 'UPDATE_PRODUCT_PRICE_SUCCESS',
   UPDATE_PRODUCT_PRICE_FAILED: 'UPDATE_PRODUCT_PRICE_FAILED',

   SET_ALL_PRICE_PRODUCT_LOADING: 'SET_ALL_PRICE_PRODUCT_LOADING',
   SET_ALL_PRICE_PRODUCT_SUCCESS: 'SET_ALL_PRICE_PRODUCT_SUCCESS',
   SET_ALL_PRICE_PRODUCT_FAILED: 'SET_ALL_PRICE_PRODUCT_FAILED',
};
