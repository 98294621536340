import { types } from "constants/App";

const initialState = {
  filter: {
    locationPath: "",
    search: "",
    date: "",
    order_by: null,
    product_supplier_id: null,
    product_brand_id: null,
    product_category_id: null,
    product_subcategory_id: null,
    product_denom_id: null,
    status: null,
    regency_id: [],
    topup_outlet_regency_id: [],
    sales_regency_id: [],
    sales_id: [],
    outlet_id: [],
    kind: null,
    kind_gift: null,
    status_penagihan: "",
    show_expired: null,
    item_type: [],
    is_auth_summary: null,
    referral_code: [],
    kind_ticket: [],
    priority_ticket: [],
    gender: null,
    work_id: null,
    device_type_id: null,
    duration_play_game_id: null,
    info_from_id: null,
    tab: "PENDING",
  },
  loading: false,
  children_menu: [],
  error: null,
};

const kind = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_FILTER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.SET_FILTER_SUCCESS:
      return {
        ...state,
        filter: action.data,
        loading: false,
      };
    case types.SET_FILTER_FAILED:
      return {
        ...state,
        filter: {},
        loading: false,
      };

    case types.SET_CHILDREN_MENU:
      return {
        ...state,
        children_menu: action.data,
        loading: false,
      };

    case types.UPDATE_CHILDREN_MENU_BADGE:
      return {
        ...state,
        children_menu: action.data,
        loading: false,
      };

    case types.SET_FILTER_INITIAL:
      return {
        ...state,
        filter: {
          locationPath: "",
          search: "",
          date: "",
          order_by: null,
          product_supplier_id: null,
          product_brand_id: null,
          product_category_id: null,
          product_subcategory_id: null,
          product_denom_id: null,
          status: null,
          regency_id: [],
          topup_outlet_regency_id: [],
          sales_regency_id: [],
          sales_id: [],
          outlet_id: [],
          kind: null,
          kind_gift: null,
          status_penagihan: "",
          show_expired: null,
          item_type: [],
          is_auth_summary: null,
          referral_code: [],
          kind_ticket: [],
          priority_ticket: [],
          gender: null,
          work_id: null,
          device_type_id: null,
          duration_play_game_id: null,
          info_from_id: null,
          tab: "PENDING",
        },
        loading: false,
      };
    default:
      return state;
  }
};

export default kind;
