import { types } from "constants/Setting";
import languageData from "resources/lngProvider/languageData";
const initialState = {
   setting: {
      locale: languageData[process.env.REACT_APP_LANG === "id" ? 1 : 0]
   }
};

const setting = (state = initialState.setting, action) => {
   switch (action.type) {
      case types.SWITCH_LANGUAGE:
         return {
            ...state,
            locale: action.locale
         };
      default:
         return state;
   }
};

export default setting;
