export const URL_UPDATE_PASSWORD = `/account/update-password`;
export const URL_UPDATE_PIN = `/account/update-pin`;
export const URL_FORGOT_PIN = `/account/forgot-pin`;
export const URL_FORGOT_PIN_CHECK = `/account/forgot-pin?forgot_key=%(key)s`;
export const URL_FORGOT_PIN_CONFIRM = `/account/forgot-pin-confirm?forgot_key=%(key)s`;

// Types
export const types = {
   UPDATE_PROFILE_LOADING: 'UPDATE_PROFILE_LOADING',
   UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
   UPDATE_PROFILE_FAILED: 'UPDATE_PROFILE_FAILED',

   CHECK_PIN_LOADING: 'CHECK_PIN_LOADING',
   CHECK_PIN_SUCCESS: 'CHECK_PIN_SUCCESS',
   CHECK_PIN_FAILED: 'CHECK_PIN_FAILED',
};
