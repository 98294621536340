export const URL_GET_NOTIFICATION = `/notifications?_limit=${5}%(params)s`;
export const URL_READ_NOTIFICATION = `/notifications/%(id)s`;

// Types
export const types = {
  GET_NOTIFICATION_LOADING: "GET_NOTIFICATION_LOADING",
  GET_NOTIFICATION_SUCCESS: "GET_NOTIFICATION_SUCCESS",
  GET_NOTIFICATION_FAILED: "GET_NOTIFICATION_FAILED",

  READ_NOTIFICATION_LOADING: "READT_NOTIFICATION_LOADING",
  READ_NOTIFICATION_SUCCESS: "READT_NOTIFICATION_SUCCESS",
  READ_NOTIFICATION_FAILED: "READT_NOTIFICATION_FAILED",
};
