// URL
export const URL_LOGIN = '/account/sign-in';
export const URL_LOGOUT = '/account/sign-out';
export const URL_PROFILE = '/account/profile';
export const REGISTER_FCM = `/account/register-fcm`;
export const URL_REFRESH_TOKEN = `/admin-tokens/refresh-token`;
export const URL_FORGOT_PASSWORD = `/account/forgot-password`;
export const URL_FORGOT_PASSWORD_CHECK = `/account/forgot-password?forgot_key=%(key)s`;
export const URL_FORGOT_PASSWORD_CONFIRM = `/account/forgot-password-confirm?forgot_key=%(key)s`;

// Types
export const types = {
   AUTH_LOADING: 'AUTH_LOADING',
   AUTH_SUCCESS: 'AUTH_SUCCESS',
   AUTH_FAILED: 'AUTH_FAILED',

   FORGOT_PASSWORD_LOADING: 'FORGOT_PASSWORD_LOADING',
   FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
   FORGOT_PASSWORD_FAILED: 'FORGOT_PASSWORD_FAILED',

   CHECK_FORGOT_PASSWORD_LOADING: 'CHECK_FORGOT_PASSWORD_LOADING',
   CHECK_FORGOT_PASSWORD_SUCCESS: 'CHECK_FORGOT_PASSWORD_SUCCESS',
   CHECK_FORGOT_PASSWORD_FAILED: 'CHECK_FORGOT_PASSWORD_FAILED',

   UPDATE_FORGOT_PASSWORD_LOADING: 'UPDATE_FORGOT_PASSWORD_LOADING',
   UPDATE_FORGOT_PASSWORD_SUCCESS: 'UPDATE_FORGOT_PASSWORD_SUCCESS',
   UPDATE_FORGOT_PASSWORD_FAILED: 'UPDATE_FORGOT_PASSWORD_FAILED',

   LOGOUT_LOADING: 'LOGOUT_LOADING',
   LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
   LOGOUT_FAILED: 'LOGOUT_FAILED',
};
