import { types } from 'constants/Account';

const initialState = {
   account: {
      onUpdate: {
         loading: false,
         error: '',
      },
      onCheckPin: {
         loading: false,
         error: '',
         success: false,
      },
   },
};

const account = (state = initialState.account, action) => {
   switch (action.type) {
      case types.UPDATE_PROFILE_LOADING:
         return {
            ...state,
            onUpdate: {
               ...state.onUpdate,
               loading: true,
            },
         };
      case types.UPDATE_PROFILE_SUCCESS:
         return {
            ...state,
            onUpdate: {
               ...state.onUpdate,
               loading: false,
            },
         };
      case types.UPDATE_PROFILE_FAILED:
         return {
            ...state,
            onUpdate: {
               ...state.onUpdate,
               loading: false,
               error: action.message,
            },
         };
      case types.CHECK_PIN_LOADING:
         return {
            ...state,
            onCheckPin: {
               ...state.onCheckPin,
               loading: true,
               success: false,
               error: '',
            },
         };
      case types.CHECK_PIN_SUCCESS:
         return {
            ...state,
            onCheckPin: {
               ...state.onCheckPin,
               loading: false,
               success: true,
               error: '',
            },
         };
      case types.CHECK_PIN_FAILED:
         return {
            ...state,
            onCheckPin: {
               ...state.onCheckPin,
               loading: false,
               success: false,
               error: action.message,
            },
         };

      default:
         return state;
   }
};

export default account;
