import { types } from "constants/Kind";

const initialState = {
   data: {},
   loading: false,
   error: null
};

const kind = (state = initialState, action) => {
   switch (action.type) {
      case types.GET_KIND_LOADING:
         return {
            ...state,
            loading: true
         };
      case types.GET_KIND_SUCCESS:
         return {
            ...state,
            data: action.data,
            loading: false
         };
      case types.GET_KIND_FAILED:
         return {
            ...state,
            data: {},
            loading: false
         };
      default:
         return state;
   }
};

export default kind;
