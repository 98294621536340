const { type } = require("constants/RoomConstants");

const initialState = {
  rooms: {
    create: {
      loading: false,
      error: "",
    },
    list: {
      loading: false,
      error: "",
      data: [],
      page: 1,
      totalPage: null,
      totalData: 0,
    },
    detail: {
      loading: false,
      error: "",
      data: null,
    },
  },
};

export const roomReducer = (state = initialState.rooms, action) => {
  switch (action.type) {
    case type.CREATE_ROOM_LOADING:
      return {
        ...state,
        create: {
          ...state.create,
          loading: true,
        },
      };
    case type.CREATE_ROOM_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
        },
      };
    case type.CREATE_ROOM_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          error: action.message,
        },
      };

    case type.GET_LIST_ROOM_LOADING:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };
    case type.GET_LIST_ROOM_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          data: action.data,
          totalPage: parseInt(action.totalPage),
          totalData: parseInt(action.totalData),
          page: action.page || state.list.page,
        },
      };
    case type.GET_LIST_ROOM_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: action.message,
        },
      };
    case type.GET_DETAIL_ROOM_LOADING:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
        },
      };
    case type.GET_DETAIL_ROOM_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          data: action.data,
        },
      };
    case type.GET_DETAIL_ROOM_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: action.message,
        },
      };
    default:
      return state;
  }
};
