import React, { useEffect } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import './resources/style/style.less';
import './resources/style/util.scss';
import { persistor, store } from 'redux/index';
import { PersistGate } from 'redux-persist/integration/react';
import loading from './SplashScreen';
import history from 'helpers/History';
import * as Sentry from '@sentry/browser';
import { notification } from 'antd';

if (process.env.NODE_ENV.trim() !== 'development') {
   Sentry.init({
      dsn: 'https://1765afc7643a4dc595e2b2e41d749c27@o572882.ingest.sentry.io/5723195',
      ignoreErrors: ['ResizeObserver loop limit exceeded'],
   });
}

const Login = React.lazy(
   () =>
      new Promise((resolve) =>
         setTimeout(() => resolve(import('containers/auth/login')), 1000),
      ),
);
const Page404 = React.lazy(() => import('containers/page404/Page404'));
const ForgotPassword = React.lazy(() =>
   import('containers/auth/forgotPassword'),
);
const TheLayout = React.lazy(() => import('components/layout/TheLayout'));

function App() {
   useEffect(() => {
      navigator.serviceWorker.addEventListener('message', (message) => {
         notification.info({
            message:
               message?.data?.['firebase-messaging-msg-data']?.notification
                  ?.title,
            description:
               message?.data?.['firebase-messaging-msg-data']?.notification
                  ?.body,
            placement: 'topRight',
         });
      });
   }, []);

   return (
      <Provider store={store}>
         <PersistGate persistor={persistor}>
            <React.Suspense fallback={loading}>
               <Router history={history}>
                  <Switch>
                     <Route
                        path="/login"
                        name="Login"
                        render={(props) => <Login {...props} />}
                     />
                     <Route
                        path="/forgot-password"
                        name="Forgot Password"
                        render={(props) => <ForgotPassword {...props} />}
                     />
                     <Route
                        path="/404"
                        name="Page 404"
                        render={(props) => <Page404 {...props} />}
                     />
                     <Route
                        path="/"
                        name="Home"
                        render={(props) => <TheLayout {...props} />}
                     />
                  </Switch>
               </Router>
            </React.Suspense>
         </PersistGate>
      </Provider>
   );
}

export default App;
