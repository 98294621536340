import { combineReducers } from "redux";
import auth from "redux/reducers/Auth";
import setting from "redux/reducers/Setting";
import product from "redux/reducers/Product";
import select from "redux/reducers/Select";
import kind from "redux/reducers/Kind";
import account from "redux/reducers/Account";
import notification from "redux/reducers/Notification";
import app from "redux/reducers/App";
import merchant from "redux/reducers/Merchant";
import topupReducer from "./Topup";
import { roomReducer } from "./RoomsReducer";
export default combineReducers({
  auth,
  setting,
  select,
  kind,
  account,
  notification,
  app,
  merchant,
  product,
  rooms: roomReducer,
  topup: topupReducer,
});
