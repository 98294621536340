import { types } from 'constants/Select';

const initialState = {
   select: {
      regency: {
         loading: false,
         error: '',
         data: [],
         page: 1,
         totalPage: null,
         totalData: 0,
      },
      district: {
         loading: false,
         error: '',
         data: [],
         page: 1,
         totalPage: null,
         totalData: 0,
      },
      sales: {
         loading: false,
         error: '',
         data: [],
         page: 1,
         totalPage: null,
         totalData: 0,
      },
      supplier: {
         loading: false,
         error: '',
         data: [],
         page: 1,
         totalPage: null,
         totalData: 0,
      },
      outlet: {
         loading: false,
         error: '',
         data: [],
         page: 1,
         totalPage: null,
         totalData: 0,
      },
      product_brand: {
         loading: false,
         error: '',
         data: [],
         page: 1,
         totalPage: null,
         totalData: 0,
      },
      product_category: {
         loading: false,
         error: '',
         data: [],
         page: 1,
         totalPage: null,
         totalData: 0,
      },
      product_subcategory: {
         loading: false,
         error: '',
         data: [],
         page: 1,
         totalPage: null,
         totalData: 0,
      },
      product_denom: {
         loading: false,
         error: '',
         data: [],
         page: 1,
         totalPage: null,
         totalData: 0,
      },
      product: {
         loading: false,
         error: '',
         data: [],
         page: 1,
         totalPage: null,
         totalData: 0,
      },

      article_category: {
         loading: false,
         error: '',
         data: [],
         page: 1,
         totalPage: null,
         totalData: 0,
      },
      cluster: {
         loading: false,
         error: '',
         data: [],
         page: 1,
         totalPage: null,
         totalData: 0,
      },
      activityItem: {
         loading: false,
         error: '',
         data: [],
         page: 1,
         totalPage: null,
         totalData: 0,
      },
      onCreateDenom: {
         loading: false,
         error: null,
      },
      work: {
         loading: false,
         error: '',
         data: [],
      },
      device_type: {
         loading: false,
         error: '',
         data: [],
      },
      play_duration: {
         loading: false,
         error: '',
         data: [],
      },
      info_from: {
         loading: false,
         error: '',
         data: [],
      },
      merchant: {
         loading: false,
         error: '',
         data: [],
      },
      category_product_merchant: {
         loading: false,
         error: '',
         data: [],
      },
      subcategory_product_merchant: {
         loading: false,
         error: '',
         data: [],
      },
      game: {
         loading: false,
         error: '',
         data: [],
         page: 1,
         totalPage: null,
         totalData: 0,
      },
   },
};

const select = (state = initialState.select, action) => {
   switch (action.type) {
      case types.GET_OPTACTIVITY_ITEM_LOADING:
         return {
            ...state,
            activityItem: {
               ...state.activityItem,
               loading: true,
            },
         };
      case types.GET_OPTACTIVITY_ITEM_SUCCESS:
         return {
            ...state,
            activityItem: {
               ...state.activityItem,
               loading: false,
               data: action.data,
               totalPage: parseInt(action.totalPage),
               totalData: parseInt(action.totalData),
               page: action.page,
            },
         };
      case types.GET_OPTACTIVITY_ITEM_FAILED:
         return {
            ...state,
            activityItem: {
               ...state.activityItem,
               loading: false,
               error: action.message,
            },
         };

      case types.GET_REGENCY_LOADING:
         return {
            ...state,
            regency: {
               ...state.regency,
               loading: true,
            },
         };
      case types.GET_REGENCY_SUCCESS:
         return {
            ...state,
            regency: {
               ...state.regency,
               loading: false,
               data: action.data,
               totalPage: parseInt(action.totalPage),
               totalData: parseInt(action.totalData),
               page: action.page,
            },
         };
      case types.GET_REGENCY_FAILED:
         return {
            ...state,
            regency: {
               ...state.regency,
               loading: false,
               error: action.message,
            },
         };

      case types.GET_DISTRICT_LOADING:
         return {
            ...state,
            district: {
               ...state.district,
               loading: true,
            },
         };
      case types.GET_DISTRICT_SUCCESS:
         return {
            ...state,
            district: {
               ...state.district,
               loading: false,
               data: action.data,
               totalPage: parseInt(action.totalPage),
               totalData: parseInt(action.totalData),
               page: action.page,
            },
         };
      case types.GET_DISTRICT_FAILED:
         return {
            ...state,
            district: {
               ...state.district,
               loading: false,
               error: action.message,
            },
         };

      case types.GET_OPTSUPPLIER_LOADING:
         return {
            ...state,
            supplier: {
               ...state.supplier,
               loading: true,
            },
         };
      case types.GET_OPTSUPPLIER_SUCCESS:
         return {
            ...state,
            supplier: {
               ...state.supplier,
               loading: false,
               data: action.data,
               totalPage: parseInt(action.totalPage),
               totalData: parseInt(action.totalData),
               page: action.page,
            },
         };
      case types.GET_OPTSUPPLIER_FAILED:
         return {
            ...state,
            supplier: {
               ...state.supplier,
               loading: false,
               error: action.message,
            },
         };

      case types.GET_OPTARTICLE_CATEGORY_LOADING:
         return {
            ...state,
            article_category: {
               ...state.article_category,
               loading: true,
            },
         };
      case types.GET_OPTARTICLE_CATEGORY_SUCCESS:
         return {
            ...state,
            article_category: {
               ...state.article_category,
               loading: false,
               data: action.data,
               totalPage: parseInt(action.totalPage),
               totalData: parseInt(action.totalData),
               page: action.page,
            },
         };
      case types.GET_OPTARTICLE_CATEGORY_FAILED:
         return {
            ...state,
            article_category: {
               ...state.article_category,
               loading: false,
               error: action.message,
            },
         };

      case types.GET_OPTSALES_LOADING:
         return {
            ...state,
            sales: {
               ...state.sales,
               loading: true,
            },
         };
      case types.GET_OPTSALES_SUCCESS:
         return {
            ...state,
            sales: {
               ...state.sales,
               loading: false,
               data: action.data,
               totalPage: parseInt(action.totalPage),
               totalData: parseInt(action.totalData),
               page: action.page,
            },
         };
      case types.GET_OPTSALES_FAILED:
         return {
            ...state,
            sales: {
               ...state.sales,
               loading: false,
               error: action.message,
            },
         };

      case types.GET_OPTOUTLET_LOADING:
         return {
            ...state,
            outlet: {
               ...state.outlet,
               loading: true,
            },
         };
      case types.GET_OPTOUTLET_SUCCESS:
         return {
            ...state,
            outlet: {
               ...state.outlet,
               loading: false,
               data: action.data,
               totalPage: parseInt(action.totalPage),
               totalData: parseInt(action.totalData),
               page: action.page,
            },
         };
      case types.GET_OPTOUTLET_FAILED:
         return {
            ...state,
            outlet: {
               ...state.outlet,
               loading: false,
               error: action.message,
            },
         };
      case types.GET_OPTPRODUCT_BRAND_LOADING:
         return {
            ...state,
            product_brand: {
               ...state.product_brand,
               loading: true,
            },
         };
      case types.GET_OPTPRODUCT_BRAND_SUCCESS:
         return {
            ...state,
            product_brand: {
               ...state.product_brand,
               loading: false,
               data: action.data,
               totalPage: parseInt(action.totalPage),
               totalData: parseInt(action.totalData),
               page: action.page,
            },
            product_subcategory: {
               ...state.product_subcategory,
               data: [],
            },
         };
      case types.GET_OPTPRODUCT_BRAND_FAILED:
         return {
            ...state,
            product_brand: {
               ...state.product_brand,
               loading: false,
               error: action.message,
            },
         };
      case types.GET_OPT_PRODUCT_LOADING:
         return {
            ...state,
            product: {
               ...state.product,
               loading: true,
            },
         };
      case types.GET_OPT_PRODUCT_SUCCESS:
         return {
            ...state,
            product: {
               ...state.product,
               loading: false,
               data: action.data,
               totalPage: parseInt(action.totalPage),
               totalData: parseInt(action.totalData),
               page: action.page,
            },
         };
      case types.GET_OPT_PRODUCT_FAILED:
         return {
            ...state,
            product: {
               ...state.product,
               loading: false,
               error: action.message,
            },
         };

      case types.ADD_OPT_PRODUCT_LOADING:
         return {
            ...state,
            product: {
               ...state.product,
               loading: true,
            },
         };
      case types.ADD_OPT_PRODUCT_SUCCESS:
         return {
            ...state,
            product: {
               ...state.product,
               data: [...state.product.data, ...action.data],
            },
         };

      case types.ADD_OPT_PRODUCT_FAILED:
         return {
            ...state,
            product: {
               ...state.product,
               loading: false,
               error: action.message,
            },
         };

      case types.GET_OPT_GAME_LOADING:
         return {
            ...state,
            game: {
               ...state.game,
               loading: true,
            },
         };
      case types.GET_OPT_GAME_SUCCESS:
         return {
            ...state,
            game: {
               ...state.game,
               loading: false,
               data: action.data,
               totalPage: parseInt(action.totalPage),
               totalData: parseInt(action.totalData),
               page: action.page,
            },
         };
      case types.GET_OPT_GAME_FAILED:
         return {
            ...state,
            game: {
               ...state.game,
               loading: false,
               error: action.message,
            },
         };

      case types.GET_OPTPRODUCT_CATEGORY_LOADING:
         return {
            ...state,
            product_category: {
               ...state.product_category,
               loading: true,
            },
         };
      case types.GET_OPTPRODUCT_CATEGORY_SUCCESS:
         return {
            ...state,
            product_category: {
               ...state.product_category,
               loading: false,
               data: action.data,
               totalPage: parseInt(action.totalPage),
               totalData: parseInt(action.totalData),
               page: action.page,
            },
         };
      case types.GET_OPTPRODUCT_CATEGORY_FAILED:
         return {
            ...state,
            product_category: {
               ...state.product_category,
               loading: false,
               error: action.message,
            },
         };

      case types.GET_OPTPRODUCT_SUBCATEGORY_LOADING:
         return {
            ...state,
            product_subcategory: {
               ...state.product_subcategory,
               loading: true,
            },
         };
      case types.GET_OPTPRODUCT_SUBCATEGORY_SUCCESS:
         return {
            ...state,
            product_subcategory: {
               ...state.product_subcategory,
               loading: false,
               data: action.data,
               totalPage: parseInt(action.totalPage),
               totalData: parseInt(action.totalData),
               page: action.page,
            },
            product_denom: {
               ...state.product_denom,
               data: [],
            },
         };
      case types.GET_OPTPRODUCT_SUBCATEGORY_FAILED:
         return {
            ...state,
            product_subcategory: {
               ...state.product_subcategory,
               loading: false,
               error: action.message,
            },
         };

      case types.GET_OPTPRODUCT_DENOM_LOADING:
         return {
            ...state,
            product_denom: {
               ...state.product_denom,
               loading: true,
            },
         };
      case types.GET_OPTPRODUCT_DENOM_SUCCESS:
         return {
            ...state,
            product_denom: {
               ...state.product_denom,
               loading: false,
               data: action.data,
               totalPage: parseInt(action.totalPage),
               totalData: parseInt(action.totalData),
               page: action.page,
            },
         };
      case types.GET_OPTPRODUCT_DENOM_FAILED:
         return {
            ...state,
            product_denom: {
               ...state.product_denom,
               loading: false,
               error: action.message,
            },
         };

      case types.CREATE_DENOM_LOADING:
         return {
            ...state,
            onCreateDenom: {
               ...state.onCreateDenom,
               loading: true,
            },
         };
      case types.CREATE_DENOM_SUCCESS:
         return {
            ...state,
            onCreateDenom: {
               ...state.onCreateDenom,
               loading: false,
            },
            product_denom: {
               ...state.product_denom,
               loading: false,
               data: [...state.product_denom.data, action.data],
            },
         };
      case types.CREATE_DENOM_FAILED:
         return {
            ...state,
            onCreateDenom: {
               ...state.onCreateDenom,
               loading: false,
               error: action.message,
            },
         };
      case types.GET_OPTCLUSTER_LOADING:
         return {
            ...state,
            cluster: {
               ...state.cluster,
               loading: true,
            },
         };
      case types.GET_OPTCLUSTER_SUCCESS:
         return {
            ...state,
            cluster: {
               ...state.cluster,
               loading: false,
               data: action.data,
               totalPage: parseInt(action.totalPage),
               totalData: parseInt(action.totalData),
               page: action.page,
            },
         };
      case types.GET_OPTCLUSTER_FAILED:
         return {
            ...state,
            cluster: {
               ...state.cluster,
               loading: false,
               error: action.message,
            },
         };
      case types.GET_LIST_WORK_LOADING:
         return {
            ...state,
            work: {
               ...state.work,
               loading: true,
            },
         };
      case types.GET_LIST_WORK_SUCCESS:
         return {
            ...state,
            work: {
               ...state.work,
               loading: false,
               data: action.data,
            },
         };
      case types.GET_LIST_WORK_FAILED:
         return {
            ...state,
            work: {
               ...state.work,
               loading: false,
               error: action.message,
            },
         };
      case types.GET_LIST_DEVICE_TYPE_LOADING:
         return {
            ...state,
            device_type: {
               ...state.device_type,
               loading: true,
            },
         };
      case types.GET_LIST_DEVICE_TYPE_SUCCESS:
         return {
            ...state,
            device_type: {
               ...state.device_type,
               loading: false,
               data: action.data,
            },
         };
      case types.GET_LIST_DEVICE_TYPE_FAILED:
         return {
            ...state,
            device_type: {
               ...state.device_type,
               loading: false,
               error: action.message,
            },
         };
      case types.GET_LIST_PLAY_DURATION_LOADING:
         return {
            ...state,
            play_duration: {
               ...state.play_duration,
               loading: true,
            },
         };
      case types.GET_LIST_PLAY_DURATION_SUCCESS:
         return {
            ...state,
            play_duration: {
               ...state.play_duration,
               loading: false,
               data: action.data,
            },
         };
      case types.GET_LIST_PLAY_DURATION_FAILED:
         return {
            ...state,
            play_duration: {
               ...state.play_duration,
               loading: false,
               error: action.message,
            },
         };
      case types.GET_LIST_INFO_FROM_LOADING:
         return {
            ...state,
            info_from: {
               ...state.info_from,
               loading: true,
            },
         };
      case types.GET_LIST_INFO_FROM_SUCCESS:
         return {
            ...state,
            info_from: {
               ...state.info_from,
               loading: false,
               data: action.data,
            },
         };
      case types.GET_LIST_INFO_FROM_FAILED:
         return {
            ...state,
            info_from: {
               ...state.info_from,
               loading: false,
               error: action.message,
            },
         };
      case types.GET_MERCHANT_LOADING:
         return {
            ...state,
            merchant: {
               ...state.merchant,
               loading: true,
            },
         };
      case types.GET_MERCHANT_SUCCESS:
         return {
            ...state,
            merchant: {
               ...state.merchant,
               loading: false,
               data: action.data,
            },
         };
      case types.GET_MERCHANT_FAILED:
         return {
            ...state,
            merchant: {
               ...state.merchant,
               loading: false,
               error: action.message,
            },
         };
      case types.GET_CATEGORY_PRODUCT_MERCHANT_LOADING:
         return {
            ...state,
            category_product_merchant: {
               ...state.category_product_merchant,
               loading: true,
            },
         };
      case types.GET_CATEGORY_PRODUCT_MERCHANT_SUCCESS:
         return {
            ...state,
            category_product_merchant: {
               ...state.category_product_merchant,
               loading: false,
               data: action.data,
            },
         };
      case types.GET_CATEGORY_PRODUCT_MERCHANT_FAILED:
         return {
            ...state,
            category_product_merchant: {
               ...state.category_product_merchant,
               loading: false,
               error: action.message,
            },
         };
      case types.GET_SUBCATEGORY_PRODUCT_MERCHANT_LOADING:
         return {
            ...state,
            subcategory_product_merchant: {
               ...state.subcategory_product_merchant,
               loading: true,
            },
         };
      case types.GET_SUBCATEGORY_PRODUCT_MERCHANT_SUCCESS:
         return {
            ...state,
            subcategory_product_merchant: {
               ...state.subcategory_product_merchant,
               loading: false,
               data: action.data,
            },
         };
      case types.GET_SUBCATEGORY_PRODUCT_MERCHANT_FAILED:
         return {
            ...state,
            subcategory_product_merchant: {
               ...state.subcategory_product_merchant,
               loading: false,
               error: action.message,
            },
         };
      case types.GET_GAME_LOADING:
         return {
            ...state,
            game: {
               ...state.game,
               loading: true,
            },
         };
      case types.GET_GAME_SUCCESS:
         return {
            ...state,
            game: {
               ...state.game,
               loading: false,
               data: action.data,
               totalPage: parseInt(action.totalPage),
               totalData: parseInt(action.totalData),
               page: action.page,
            },
         };
      case types.GET_GAME_FAILED:
         return {
            ...state,
            game: {
               ...state.game,
               loading: false,
               error: action.message,
            },
         };

      default:
         return state;
   }
};

export default select;
