import { types } from 'constants/Auth';

const initialState = {
   auth: {
      login: {
         loading: false,
         error: '',
         user: {},
      },
      forgot_password: {
         loading: false,
         error: '',
      },
      update_forgot_password: {
         loading: false,
         error: '',
      },
      check_forgot_password: {
         loading: false,
         error: '',
         success: false,
      },
      logout: {
         loading: '',
         error: '',
      },
   },
};

const auth = (state = initialState.auth, action) => {
   switch (action.type) {
      case types.AUTH_LOADING:
         return {
            ...state,
            login: {
               ...state.login,
               loading: true,
            },
         };
      case types.AUTH_SUCCESS:
         return {
            ...state,
            login: {
               ...state.login,
               user: action.data,
               loading: false,
               error: '',
            },
         };
      case types.AUTH_FAILED:
         return {
            ...state,
            login: {
               ...state.login,
               user: {},
               loading: false,
               error: action.message,
            },
         };

      case types.FORGOT_PASSWORD_LOADING:
         return {
            ...state,
            forgot_password: {
               ...state.forgot_password,
               loading: true,
            },
         };
      case types.FORGOT_PASSWORD_SUCCESS:
         return {
            ...state,
            forgot_password: {
               ...state.forgot_password,
               loading: false,
               error: '',
            },
         };
      case types.FORGOT_PASSWORD_PASSWORD:
         return {
            ...state,
            forgot_password: {
               ...state.forgot_password,
               loading: false,
               error: action.message,
            },
         };

      case types.UPDATE_FORGOT_PASSWORD_LOADING:
         return {
            ...state,
            update_forgot_password: {
               ...state.update_forgot_password,
               loading: true,
            },
         };
      case types.UPDATE_FORGOT_PASSWORD_SUCCESS:
         return {
            ...state,
            update_forgot_password: {
               ...state.update_forgot_password,
               loading: false,
               error: '',
            },
         };
      case types.UPDATE_FORGOT_PASSWORD_FAILED:
         return {
            ...state,
            update_forgot_password: {
               ...state.update_forgot_password,
               loading: false,
               error: action.message,
            },
         };

      case types.CHECK_FORGOT_PASSWORD_LOADING:
         return {
            ...state,
            check_forgot_password: {
               ...state.check_forgot_password,
               loading: true,
               success: false,
               error: '',
            },
         };
      case types.CHECK_FORGOT_PASSWORD_SUCCESS:
         return {
            ...state,
            check_forgot_password: {
               ...state.check_forgot_password,
               loading: false,
               success: true,
               error: '',
            },
         };
      case types.CHECK_FORGOT_PASSWORD_FAILED:
         return {
            ...state,
            check_forgot_password: {
               ...state.check_forgot_password,
               loading: false,
               error: action.message,
            },
         };

      case types.LOGOUT_LOADING:
         return {
            ...state,
            logout: {
               ...state.logout,
               loading: true,
            },
         };
      case types.LOGOUT_SUCCESS:
         return {
            ...state,
            logout: {
               ...state.logout,
               loading: false,
               error: '',
            },
         };
      case types.LOGOUT_FAILED:
         return {
            ...state,
            logout: {
               ...state.logout,
               loading: false,
               error: action.message,
            },
         };
      default:
         return state;
   }
};

export default auth;
